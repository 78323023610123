:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
}

.childStoryContainer {
  background-color: var(--white);
  max-width: 800px;
  margin: 4.6875rem auto;
  text-align: start;
  padding: 2rem 3rem;
  padding-bottom: 4rem;
  position: relative;
}

.closeStory {
  position: absolute;
  font-size: 2.5rem;
  right: 3rem;
  transform: rotate(45deg);
  color: var(--subColor);
  transition: 0.3s;
  cursor: pointer;
  z-index: 2;
  padding: 0px;
  width: fit-content;
  border: none;
  background-color: transparent;
}

.closeStory:hover {
  color: var(--mainColor);
}

.storyBody {
  position: relative;
  padding-bottom: 2rem;
}

.storyBody p {
  white-space: pre-wrap;
}

.storyBody:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background-image: linear-gradient(
    90deg,
    #a8d064,
    #a8d064 14.2857%,
    #3cab1a 0,
    #3cab1a 28.5714%,
    #008c15 0,
    #008c15 42.8571%,
    #00630f 0,
    #00630f 57.1428%,
    #005ca7 0,
    #005ca7 71.4285%,
    #003a88 0,
    #003a88 85.7142%,
    #00155c 0
  );
}

.storyHeader {
  margin-bottom: 1rem;
  padding-bottom: 1.25rem;
  position: relative;
  margin-bottom: 2rem;
}

.storyHeader::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #a8d064,
    #a8d064 14.2857%,
    #3cab1a 0,
    #3cab1a 28.5714%,
    #008c15 0,
    #008c15 42.8571%,
    #00630f 0,
    #00630f 57.1428%,
    #005ca7 0,
    #005ca7 71.4285%,
    #003a88 0,
    #003a88 85.7142%,
    #00155c 0
  );
}

.storyImageWrapper {
  padding-right: 1rem;
}

.childCardImage {
  border-radius: 50rem;
}

.aboutChild {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutChild h3 {
  font-size: 2rem;
}

.aboutChild span {
  font-weight: 700;
}

.rightAboutChild p {
  padding-right: 1rem;
  margin-bottom: 7px;
}
.rightAboutChild p span {
  color: var(--mainbg);
}
.childId {
  color: #ccc;
}
.sponsor {
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.childCardButton {
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
}
.childCardButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}

@media (max-width: 767.98px) {
  .childStoryContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .closeStory {
    right: 1.5rem;
  }
  .storyImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .childCardImage {
    height: 200px;
    width: 200px;
  }
  .aboutChild {
    text-align: center;
  }
}
