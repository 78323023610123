:root {
  --mainbg: #285c46;
  --mainbgLight: #285c469e;
  --mainColor: #ca830e;
  --mainColorLight: #ca820e9f;
  --subColor: #e44244;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.removeContainer {
  background-color: var(--white);
  padding: 5rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  display: flex;
}

.buttons {
  display: flex;
  justify-content: space-around;
}
.cancelDeleteButton,
.deleteAChildButton {
  background-color: var(--mainbgLight);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
  margin: 1rem;
}
.cancelDeleteButton:hover {
  background-color: var(--mainbg);
  color: var(--white);
}
.deleteAChildButton {
  background-color: var(--subColorLight);
}
.deleteAChildButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}
