:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}
.childrenWrapper {
  background-color: var(--mainbg);
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.childCardHolder {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}

.addChildrenContainer {
  padding: 2rem;
  padding-top: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addChildrenButton {
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.addChildrenButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}
