:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.enteredHobbies {
  width: 100%;
  min-height: 50px;
  background-color: #285c463c;
  border-radius: 8px;
  padding: 1rem;
}

.eachHobby {
  margin: 0.5rem;
  padding: 0.3rem;
  border-radius: 10px;
  font-size: 14px;
  color: var(--mainbg);
  position: relative;
  background-color: var(--white);
  display: inline-block;
}

.eachHobby button {
  border-radius: 50%;
  border: none;
  transform: rotate(45deg) translateY(-50%);
  transition: 0.3s;
  background-color: var(--mainbg);
  color: var(--white);
  position: absolute;
  right: -2px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  padding: 0rem;
  line-height: 2px;
}
.eachHobby button:hover {
  background-color: #3f916e;
}

.addingHobby {
  padding-top: 1rem;
}

.addhobby {
  border: none;
  margin-left: 5px;
  background-color: var(--mainbg);
  border-radius: 8px;
  font-size: 14px;
  padding: 0.5rem 1rem;
  color: var(--white);
  transition: 0.3s;
}
.addhobby:hover {
  background-color: #3f916e;
}

@media (max-width: 767.98px) {
  .addhobby {
    margin-top: 0.5rem;
  }
}

@media (max-width: 575.98px) {
  .addingHobby input {
    width: 100%;
  }
}
