:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.sponsorPage {
  background-color: #f3f0e9;
  padding-top: 5rem;
  text-align: center;
  top: 0;
}

.sponsorHeadWelcome {
  font-weight: bold;
  margin: 2rem auto;
}

.article {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.childDetails,
.contactInformation {
  padding: 3rem 2rem;
  background-color: var(--white);
  position: relative;
  margin-bottom: 2rem;
}

.childDetails:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.childImageHolder {
  padding: 0rem 1rem;
}
.childImageHolder img {
  border-radius: 50rem;
  width: 100%;
}

.childDetailsHolder {
  text-align: start;
}

.childDetailsHolder h3 span {
  font-weight: bold;
}

.childDetailsHolder h3 {
  font-size: 2.1rem;
}

.childCardStory {
  display: block;
  background-color: transparent;
  color: inherit;
  border: none;

  padding-bottom: 1rem;
}
.childCardStory:hover {
  background-color: transparent;
  text-decoration: underline;
  color: var(--mainbg);
}

.storyIcon {
  color: var(--mainbg);
  padding-right: 0.5rem;
}

.sponsorshipPlan {
  color: var(--mainColor);
  text-align: start;
}

.monthlyAmount {
  border: none;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--mainbg);
}

form {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.sectionHeader {
  text-transform: uppercase;
  color: var(--mainbg);
  letter-spacing: normal;
  font-weight: bold;
  text-align: center;
}

.contactInformation {
  padding: 2rem 5rem;
  text-align: start;
  margin-bottom: 2rem;
  text-align: center;
}

.contactInformation:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.isOrganisationHidden {
  animation: isOrganisationAnimationHidden 1s;
  display: none;
  width: 100%;
  box-sizing: border-box;
  transition: 0.5ss;
}

.isOrganisationShow {
  display: block;
  transition: 0.5s;
  animation: isOrganisationAnimationShow 0.5s;
}

@keyframes isOrganisationAnimationHidden {
  0% {
    opacity: 1;
    height: fit-content;
  }

  50% {
    height: 0px;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes isOrganisationAnimationShow {
  0% {
    opacity: 0;
  }

  80% {
    height: fit-content;
    opacity: 0;
  }
  100% {
    display: block;
  }
}

.formInputs {
  display: flex;
  flex-direction: column;
}

.formInputs label {
  text-align: start;
}
input,
select {
  height: 40px;
  border-radius: 8px;
  border: 2px solid var(--pale);
}

.sponsorButton {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
}

.sponsorButton:hover {
  background-color: var(--mainbg);
}

.LoadingStep {
  height: 70vh;
}

@media (max-width: 767.98px) {
  .article {
    width: auto;
  }

  .contactInformation {
    padding: 2rem 1.5rem;
  }

  .smallerMobile {
    width: 100%;
  }
}
