:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.addachildPage {
  background-color: #f3f0e9;
  padding-top: 5rem;
  text-align: center;
  top: 0;
}

.article {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.childInformation {
  padding: 2rem 5rem;
  background-color: var(--white);
  position: relative;
  margin-bottom: 2rem;
}

.childInformation:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.sectionHeader {
  text-transform: uppercase;
  color: var(--mainbg);
  letter-spacing: normal;
  font-weight: bold;
  text-align: center;
}

.formInputs {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.formInputs label,
.formInputs input {
  padding-left: 5px;
}

textarea {
  border-radius: 8px;
  border: 2px solid var(--pale);
}

.addChildButton {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
}

.addChildButton:hover {
  background-color: var(--mainbg);
}

@media (max-width: 991.98px) {
  .article {
    max-width: 700px;
  }
}

@media (max-width: 767.98px) {
  .article {
    max-width: 600px;
  }
  .childInformation {
    padding: 2rem 3rem;
  }
}

@media (max-width: 575.98px) {
  .article {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .childInformation {
    padding: 2rem;
  }
}
