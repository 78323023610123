:root {
  --mainbg: #285c46;
  --mainColor: #ca830e;
  --subColor: #e44244;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.donut {
  width: 3rem;
  height: 3rem;
  margin: 2rem auto;
  border-radius: 50%;
  border: 0.3rem solid var(--pale);
  border-top-color: var(--mainColor);
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.69, 0.12, 0.83, 0.67);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
